import React from 'react'
import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'
import MainImage from '../../components/MainImage/MainImage'
import { appRoutes } from '../../containers/Router/routes'
import { CheckCircleOutlined } from '@ant-design/icons'

const SuccessView = () => {
  return (
    <div>
      <MainImage image='main_image_yvs4hz.jpg' small />
      <Result
        status='success'
        icon={<CheckCircleOutlined />}
        title='Płatność powiodła się'
        subTitle='Gratulujemy. Teraz możesz bez ograniczeń korzystać z platformy pilates-online'
        extra={
          <Link
            to={appRoutes.videosList.path}
          >
            <Button
              type='primary'
            >
              Przeglądaj filmy
            </Button>
          </Link>
        }
      />
    </div>
  )
}

export default SuccessView
