import React from 'react'
import ContentLoader from '../../../../components/ContentLoader'
import { Button, Descriptions, Popconfirm, Typography } from 'antd'
import { Client } from '../../../../providers/ClientProvider/client/services/clients/types'
import moment from 'moment'
import { appRoutes } from '../../../../containers/Router/routes'
import { Link } from 'react-router-dom'

interface Props {
  isActiveSubscription: boolean
  stripeSubscription?: any
  stripeSubscriptionStatus: 'loading' | 'error' | 'success'
  cancelSubscription: () => void
  deleteSubscriptionStatus: 'idle' | 'loading' | 'error' | 'success'
  renew: () => void
  renewing: boolean
  changePaymentMethod: () => void
  paymentMethod: boolean
  client?: Client
}

const SubscriptionView = (props: Props) => {
  return (
    <ContentLoader loading={props.stripeSubscriptionStatus === 'loading'}>
      {
        props.isActiveSubscription
          ? <>
            <Descriptions title='Dane abonamentu:'>
              {props.stripeSubscription ?
                <>
                  <Descriptions.Item label='Cena'>{(props.stripeSubscription?.plan.amount / 100) + ',00 zł'}</Descriptions.Item>
                  <Descriptions.Item label='Abonament'>{props.stripeSubscription?.plan.interval === 'month' ? 'Miesięczny' : 'Roczny'}</Descriptions.Item>
                </> : null}
              <Descriptions.Item label='Ważny do'>{moment(props.client?.accessExpiryDate).format('DD/MM/yyyy')}</Descriptions.Item>
            </Descriptions>
            <Button
              onClick={props.changePaymentMethod}
              loading={props.paymentMethod}
              style={{ margin: '10px 10px 10px 0' }}
            >
              Dodaj metodę płatności
            </Button>
            {props.stripeSubscription?.cancel_at_period_end
              ? <>
                <Typography.Paragraph>Subskrypcja wygaśnie na koniec okresu rozliczeniowego</Typography.Paragraph>
                <Button
                  onClick={props.renew}
                  loading={props.renewing}
                  style={{ margin: '0 10px 10px 0' }}
                >
                  Wznów
                </Button>
              </>
              : <Popconfirm
                placement='topLeft'
                title='Czy na pewno chcesz anulować subskrybuję?'
                okText='Tak'
                cancelText='Nie'
                trigger='click'
                onConfirm={props.cancelSubscription}
              >
                <Button
                  loading={props.deleteSubscriptionStatus === 'loading'}
                  style={{ margin: '10px 10px 10px 0' }}
                >
                  Anuluj subskrypcję
                </Button>
              </Popconfirm>
            }
            <Typography.Paragraph>
              Jeśli chcesz zmienić subskrypcję skontaktuj się z nami za pomocą <Link to={appRoutes.help.path}>formularza kontaktowego</Link>
            </Typography.Paragraph>
          </>
          : <>
            <Typography.Paragraph>Subskrypcja nieaktywna</Typography.Paragraph>
            <Button
              onClick={props.renew}
              loading={props.renewing}
              style={{ margin: '0 10px 10px 0' }}
            >
              Wznów/opłać
            </Button>
          </>
      }
    </ContentLoader>
  )
}

export default SubscriptionView
