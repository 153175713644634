import feathers from '@feathersjs/client'
import auth from '@feathersjs/authentication-client'
import { createContext } from 'react'

import appHooks from './app.hooks'
import { User } from './services/users/types'
import { Application } from '@feathersjs/feathers'
import { CRUDServiceType } from './services/CRUDService'
import { FAQ } from './services/faq/types'
import { Video } from './services/videos/types'
import { Target } from './services/targets/types'
import { Level } from './services/levels/types'
import { Equipment } from './services/equipments/types'
import { PrivacyPolicy } from './services/privacyPolicies/types'
import { Favorite } from './services/favorites/types.d'
import { Planned } from './services/planned/types'
import { Watched } from './services/watchedVideos/types'
import { Client } from './services/clients/types'
import { StripePaymentMethods } from './services/stripePaymentMethods/type'
import { StripeSetupIntent } from './services/stripeSetupIntents/types'
import { StripeCustomer } from './services/stripeCustomers/types'
import { StripeSubscription } from './services/stripeSubscriptions/types'
import { StripeCheckoutSession } from './services/stripeCheckoutSession/types'
import { StripeBillingPortal } from './services/stripeBillingPortal/types'
import { StripePlan } from './services/stripePlans/types'
import { TermsAndConditions } from './services/termsAndConditions/types'
import { VideoTarget } from './services/videoTargets/types'
import { StripeInvoices } from './services/stripeInvoices/types'
import { ContactForm } from './services/contactForm/types'
import { Program } from './services/programs/types'
import { Newsletter } from './services/newsletter/types'
import { VideoPodcast } from './services/videoPodcasts/types'

// A mapping of service names to types. Will be extended in service files.

export interface ServiceModels {
  'users': User
  'faqs': FAQ
  'videos': Video
  'targets': Target
  'levels': Level
  'equipments': Equipment
  'privacyPolicies': PrivacyPolicy
  'termsAndConditions': TermsAndConditions
  'favoriteVideos': Favorite
  'plannedVideos': Planned
  'watchedVideos': Watched
  'clients': Client
  'clientAuthManagement': any
  'stripePaymentMethods': StripePaymentMethods
  'stripeSetupIntents': StripeSetupIntent
  'stripeCustomers': StripeCustomer
  'stripeSubscriptions': StripeSubscription
  'stripeCheckoutSessions': StripeCheckoutSession
  'stripeBillingPortal': StripeBillingPortal
  'stripeInvoices': StripeInvoices
  'stripePlans': StripePlan
  'videoTargets': VideoTarget
  'contactForm': ContactForm
  'programs': Program,
  'newsletter': Newsletter
  'videoPodcasts': VideoPodcast
}

type ServiceTypes = CRUDServiceType<ServiceModels>

// The application instance type that will be used everywhere else
export type IClient = Application<ServiceTypes>

const client: IClient = feathers()

// Connect to a different URL
const restClient = feathers.rest(process.env.REACT_APP_API_URL)

// Configure an AJAX library (see below) with that client
client.configure(restClient.fetch(window.fetch))
client.configure(auth({
  storageKey: process.env.REACT_APP_JWT_STORAGE_KEY
}))

const ClientContext = createContext<IClient>(client)

let clientInitialized = false

function initClient (dispatch: any, history: any) {
  // React.StrictMode causes components to render twice at the start
  // because of this we need to secure client init with clientInitialized
  // so the hooks do not get set up twice
  if (!clientInitialized) {
    client.hooks(appHooks(dispatch, history))
    clientInitialized = true
  }

  return client
}

export default ClientContext
export {
  initClient
}
