import { useGetQuery } from '../../query'

const useSelectSubscription = () => {

  const useNewPrices = process.env.REACT_APP_NEW_PRICES === 'true'

  const monthlyPrice = useNewPrices ? 'price_1QHkx8A9eQ3FzEF5SzHx8z4z' : 'price_1H837ZA9eQ3FzEF5FJW7owGj'
  const yearlyPrice = useNewPrices ? 'price_1QHkxKA9eQ3FzEF5hnRlx431' : 'price_1H837aA9eQ3FzEF5SWsPdwAE'


  const {
    data: subscriptionPlan1,
    status: subscriptionPlan1Status
  } = useGetQuery('stripePlans', monthlyPrice)

  const {
    data: subscriptionPlan2,
    status: subscriptionPlan2Status
  } = useGetQuery('stripePlans', yearlyPrice)

  return {
    subscriptionPlan1,
    subscriptionPlan2
  }
}

export default useSelectSubscription
